import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ClientIcon from '../ClientIcon'
import { getRoutesByLang, routeNames } from '../../utils/helpers/routes'
import {
  animateUseCasesContent,
  animateUseCasesTitle,
} from '../../utils/page-animations/services'
import LocalizedTransitionLink from '../LocalizedTransitionLink'
import * as Vivus from 'vivus'

const UseCases = ({ language, content, animation, pageContext, search }) => {
  const { t } = useTranslation()
  const localizedRoutes = getRoutesByLang(language)
  const [vivus, setVivus] = useState(null)
  const titleRef = useRef(null)
  const useCasesRef = useRef(null)

  const initVivus = () => {
    const vivusAnim = new Vivus('use-cases__svg', {
      file: '/images/doodle.svg',
      duration: 20,
      type: 'oneByOne',
      start: 'manual',
      onReady: () => {
        setVivus(vivusAnim)
      },
    })
  }

  useEffect(() => {
    if (animation.isReady && animation.canStart) {
      animateUseCasesTitle({
        onComplete: () => {
          if (vivus) {
            vivus.play()
          }
        },
      })
      animateUseCasesContent()
    }
  }, [animation, vivus])

  useEffect(() => {
    if (titleRef.current && !vivus) {
      initVivus()
    }
  }, [vivus])

  useEffect(() => {
    if (search && useCasesRef.current && content.length) {
      let params = new URLSearchParams(search)
      const useCaseId = params.get('useCase')

      setTimeout(() => {
        document.getElementById(useCaseId)?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        })
      }, 100)
    }
  }, [search, useCasesRef, content])

  return (
    <>
      <section
        className="container use-cases__title pb-6 pb-lg-8"
        ref={titleRef}
      >
        <div className="row">
          <div className="col col-lg-8 col-lg-offset-3">
            <h5 className="ft-center ft-400 mb-1 ">
              {t('service:selected_work:title')}
            </h5>
            <div className="ft-center ft-default-m-small c-gray-300 ft-300 mb-5">
              {t('service:selected_work:desc')}
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(`service:selected_work:${pageContext.uid}`),
                  }}
                ></span>
                <div id="use-cases__svg" className="use-cases__svg"></div>
              </span>
            </div>
          </div>
        </div>
      </section>
      <section
        ref={useCasesRef}
        className={`container use-cases bg-before-gray-400 ${
          content.length === 3 && 'use-cases--3'
        }`}
      >
        <div className="row">
          <div className="col col-lg-12 col-xl-10 col-lg-offset-1 col-xl-offset-2">
            {content.map((caseStudy, key) => {
              return (
                <div
                  key={key}
                  className="use-cases__card"
                  id={caseStudy.case_study_title?.text}
                >
                  <div
                    className={`use-cases__thumbnail f ${
                      caseStudy.case_study_reversed
                        ? 'f-direction-row-reverse'
                        : ''
                    }`}
                  >
                    <img
                      src={caseStudy.case_study_main_picture.url}
                      alt="Use cases main thumbnail"
                    />
                    <div className="f f-direction-column hide-mobile">
                      <img
                        src={caseStudy.case_study_first_picture.url}
                        alt="Use cases first thumbnail"
                      />
                      {caseStudy.case_study_second_picture.url && (
                        <img
                          src={caseStudy.case_study_second_picture.url}
                          alt="Use cases second thumbnail"
                        />
                      )}
                    </div>
                  </div>

                  <div className="use-cases__content">
                    <div className="f f-direction-column f-jc-space-b">
                      <h5 className="ft-400 mb-4 mb-md-0">
                        {caseStudy.case_study_title.text}
                      </h5>
                      <div className="f f-ai-center f-jc-space-b">
                        {caseStudy.case_study_company === 'Confidential' ? (
                          <p className="ft-secondary c-alpha-6 mb-0">
                            {t('service:confidential')}
                          </p>
                        ) : (
                          <ClientIcon client={caseStudy.case_study_company} />
                        )}
                        {caseStudy.case_study_view_more_link?.uid && (
                          <div className="hide-desktop">
                            <a
                              href={`${localizedRoutes[routeNames.projects]}/${
                                caseStudy.case_study_view_more_link?.uid
                              }`}
                              className="btn btn--secondary btn--small"
                            >
                              {t('service:view_more')}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="hide-mobile">
                      <p className="mb-0">
                        {caseStudy.case_study_description.text}
                      </p>
                      {caseStudy.case_study_description.length > 0 && (
                        <ul className="mt-2">
                          {caseStudy.case_study_description
                            .slice(1)
                            .map((bullet, index) => {
                              return <li key={index}>{bullet.text}</li>
                            })}
                        </ul>
                      )}
                      {(caseStudy.case_study_view_more_link?.uid ||
                        caseStudy.case_study_view_more_link?.url) && (
                        <LocalizedTransitionLink
                          exitTransitionType="none"
                          entryTransitionType="none"
                          addTrailingSlash={
                            !!caseStudy.case_study_view_more_link?.uid
                          }
                          to={`${localizedRoutes[routeNames.projects]}/${
                            caseStudy.case_study_view_more_link?.uid ||
                            caseStudy.case_study_view_more_link?.url
                          }`}
                          className="btn btn--secondary btn--small mt-5"
                        >
                          {t('service:view_more')}
                        </LocalizedTransitionLink>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default UseCases
