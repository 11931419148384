import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ClientIcon from '../../ClientIcon'
import { getRoutesByLang, routeNames } from '../../../utils/helpers/routes'
import LocalizedTransitionLink from '../../LocalizedTransitionLink'
import { HistoryContext } from '../../../utils/hooks/History'

const CardService = ({ language, data, accordionIndex }) => {
  const { addRouteToHistory } = useContext(HistoryContext)
  const { t } = useTranslation()
  const localizedRoutes = getRoutesByLang(language)

  const CardContent = () => {
    return (
      <>
        <div className="card-service__title f f-jc-space-b f-direction-column mb-3 mb-lg-0">
          <h6 className="ft-400 mb-2 mb-md-0">{data.card_title.text}</h6>
          {}
          {data.card_logo === 'confidential' ? (
            <p className="ft-secondary c-gray-300 c-alpha-6 mb-0">
              {t('service:confidential')}
            </p>
          ) : (
            <ClientIcon client={data.card_logo} />
          )}
        </div>
        <div className="card-service__list">
          {data.card_content.html && (
            <div
              dangerouslySetInnerHTML={{ __html: data.card_content.html }}
            ></div>
          )}
          {(data.card_link.uid || data.card_link.url) && (
            <p className="link link--yellow link--arrow">
              {t('service:view_more')}
            </p>
          )}
        </div>
      </>
    )
  }

  const onAccordionClick = () => {
    const currentRoute = window.location.origin + window.location.pathname
    addRouteToHistory({
      path: `${currentRoute}?accordionIndex=${accordionIndex}`,
      type: 'root',
      scrollView: 0,
      name: data.card_title.text,
    })
  }

  return (
    <>
      {data.card_link.uid && (
        <div
          role="button"
          tabIndex={0}
          onClick={onAccordionClick}
          onKeyPress={onAccordionClick}
          className="card-service"
        >
          <LocalizedTransitionLink
            exitTransitionType="none"
            entryTransitionType="none"
            to={`${localizedRoutes[routeNames.projects]}/${data.card_link.uid}`}
            className="card-service__link"
          >
            <CardContent />
          </LocalizedTransitionLink>
        </div>
      )}
      {data.card_link.url && (
        <a href={data.card_link.url} className="card-service">
          <CardContent />
        </a>
      )}
      {!data.card_link.url && !data.card_link.uid && (
        <div className="card-service">
          <CardContent />
        </div>
      )}
    </>
  )
}

export default CardService
